<template>
    <span class="highlight tooltip">
        {{ label }}
        <ins :class="image"></ins>
    </span>
</template>

<script>
    export default {
        name: 'tooltip',
        props: {
          label: {
            type: String,
            default: 'tooltip text',
            required: true
          },
          image: {
            type: String,
            default: 'image',
            required: true
          }
        },
        data() {
            return {
                loaded: false
            };
        }
    };
</script>

<style lang="scss">
    .highlight {
        border-bottom: 1px dashed $base-font-color;
        padding: 0 .2em;
    }

    .tooltip {
        cursor: text;
        display: inline-block;
        line-height: 28px;
        position: relative;

        ins {
            @include position (absolute, auto, auto, 2em, 2px);
            @include opacity (0);
            border: 3px solid darken($yellow-light, 10%);
            box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, .5);
            content: '';
            display: block;
            margin: 0;
            z-index: -1;

            &::after {
                @include position (absolute, auto, auto, -8px, 5px);
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid darken($yellow-light, 10%);
                content: '';
                display: block;
                margin-left: -5px;
            }

            &.lyndsey {
                @include image-insert-icon ('/images/lyndsey.jpg', 176px, 176px, 0 0);
            }

            &.tunga {
                @include image-insert-icon ('/images/tunga.jpg', 220px, 165px, 0 0);
            }

            &.boston {
                @include image-insert-icon ('/images/medford.png', 220px, 176px, 0 0);
            }

            &.coffee {
                @include image-insert-icon ('/images/coffee.jpg', 220px, 176px, 0 0);
            }

            &.lyndsey,
            &.tunga,
            &.boston,
            &.coffee {
                background-size: contain;
                overflow: visible;
            }
        }

        &:hover {
            border-bottom-color: $kanye-pink;

            ins {
                @include opacity (1);
                z-index: 100;
            }
        }
    }
</style>
