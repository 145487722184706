'use strict';

import '../_sass/main.scss';

// const requireAll = (r) => {
//     r.keys().forEach(r);
// };

// requireAll(require.context('images/', true, /\.(png|jpg|jpeg|gif)(\?.*$|$)/));

import Vue from 'vue';
import icon from './components/icon.vue';
import tooltip from './components/tooltip.vue';
import telepresence from './components/telepresence.vue';

Vue.config.debug = true;
Vue.config.devtools = true;

new Vue({
    el: '#app',
    components: {
        icon,
        tooltip,
        telepresence
    }
});
